<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Combo">
      <!-- Tabs -->
      <vs-tabs v-model="selected.tab">
        <vs-tab label="List">
          <div class="con-tab-ejemplo">
            <list-combo
              :selected="selected"
              :option="option"
              @changeTab="selectedTab"
            ></list-combo>
          </div>
        </vs-tab>
        <vs-tab label="Form">
          <div class="con-tab-ejemplo">
            <form-combo
              :selected="selected"
              :option="option"
              @changeTab="selectedTab"
            ></form-combo>
          </div>
        </vs-tab>
        <vs-tab label="Import">
          <div class="con-tab-ejemplo">
            <import-combo
              :selected="selected"
              :option="option"
              @changeTab="selectedTab"
            ></import-combo>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import list from "./list/list.vue";
import form from "./form/form.vue";
import importV2 from "./import/import.vue";

export default {
  components: {
    "list-combo": list,
    "form-combo": form,
    "import-combo": importV2,
  },
  data() {
    return {
      option: {
        territory: [],
      },
      selected: {
        territory: {},
        tab: 0,
        itemKit: {},
        isEdit: true,
      },
    };
  },
  methods: {
    selectedTab(value, option, itemKit, isEdit = false) {
      this.selected.itemKit = itemKit;
      this.selected.tab = value;
      this.selected.isEdit = isEdit;
    },
  },
};
</script>
